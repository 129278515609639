window.catHi = new URL('./avatar/cat_hi.riv', import.meta.url);
window.bigeye = new URL('./avatar/bigeye.riv', import.meta.url);
window.catSlap = new URL('./avatar/cat_slap.riv', import.meta.url);
window.laser = new URL('./avatar/laser.riv', import.meta.url);
window.angry = new URL('./avatar/angry.riv', import.meta.url);
window.love = new URL('./avatar/love.riv', import.meta.url);
window.explode = new URL('./img/explode.riv', import.meta.url);
window.roadCar = "https://cdn.rive.app/animations/off_road_car_v7.riv";
window.catwithhat = new URL('./avatar/catwithhat.riv', import.meta.url);
window.live2ddemo = "https://docs.live2d.com/wp-content/uploads/2018/12/running-1.png";
window.imageDemo = "https://media.giphy.com/media/QiIy9byvKGU1oCwlWf/giphy.gif";
window.live2ddemo2 = "https://cdn.jsdelivr.net/gh/guansss/pixi-live2d-display/test/assets/shizuku/shizuku.model.json";
window.live2ddemo2v2 = "https://cdn.jsdelivr.net/gh/guansss/pixi-live2d-display/test/assets/haru/haru_greeter_t03.model3.json";
window.catwithhatsvg = new URL('./avatar/catwithhat.svg', import.meta.url);


createPlayer(window.catHi,7,"rive");
createPlayer(window.bigeye,1,"rive");
createPlayer(window.roadCar,2,"rive");
createPlayer(window.catSlap,3,"rive");
createPlayer(window.laser,4,"rive");
createPlayer(window.angry,5,"rive");
createPlayer(window.love,6,"rive");
createPlayer(window.catwithhat,10,"rive");
createPlayer(window.live2ddemo,8,"website");
createPlayer(window.imageDemo,9,"image");
createPlayer(window.catwithhatsvg,0,"image",null,null,70,60);

var onhit1 = function(model){// handle tapping
	model.on("hit", (hitAreas) => {
		if (hitAreas.includes("body")){model.motion("tap_body");console.log("body");}
		if (hitAreas.includes("head")) {model.expression();console.log("head");}
	});
}
createPlayer(window.live2ddemo2,10,"live2d",0,0,200,200,onhit1);

var onhit2 = function(model){// handle tapping
	model.on("hit", hitAreas => {
		if (hitAreas.includes("Body")) {model.motion("Tap");console.log("body");}
		if (hitAreas.includes("Head")) {model.expression();console.log("head");}
	});
}
createPlayer(window.live2ddemo2v2,11,"live2d",0,0,140,300,onhit2);


function createPlayer(url, id, type, x, y, width, height, onhit) {
	if (x == null) x = 120 + Math.random()*800;
	if (y == null) y = 120 + Math.random()*400;
	
	if (width == null) width = 100;
	if (height == null) height = 100;
	
	if (type == "rive"){
		var newCanvas = `<canvas id=${id} width="${width}" height="${height}" style="position:fixed; top: 0; left: 0" onmousedown="clickPlayerHandler(true,${id});" onmouseup="clickPlayerHandler(false,${id});"></canvas>`;
		document.getElementById("gameCanvas").insertAdjacentHTML("beforeend", newCanvas);
		
		new rive.Rive({
			src: url,
			canvas: document.getElementById(id),
			autoplay: true,
			layout: new rive.Layout({fit: 'cover', alignment: 'center'}),
		});
		
		playerList[id] = {x:x,y:y,speedX:0,speedY:0,id:id,radius:50};
	}
	else if (type == "website"){
		var newCanvas = `<div id=${id} style="position:fixed; top: 0; left: 0" onmousedown="clickPlayerHandler(true,${id});" onmouseup="clickPlayerHandler(false,${id});"><iframe src="${url}" width="${width}" height="${height}" frameBorder="0" style="pointer-events:none;user-select: none;" scrolling="no"></iframe></div>`;
		document.getElementById("gameCanvas").insertAdjacentHTML("beforeend", newCanvas);
		
		
		playerList[id] = {x:x,y:y,speedX:0,speedY:0,id:id,radius:50};
	}
	else if (type == "live2d"){
		var newCanvas = `<canvas id=${id} width="${width}" height="${height}" style="position:fixed; top: 0; left: 0" onmousedown="clickPlayerHandler(true,${id});" onmouseup="clickPlayerHandler(false,${id});"></canvas>`;
		document.getElementById("gameCanvas").insertAdjacentHTML("beforeend", newCanvas);

		(async function main() {
			const app = new PIXI.Application({
				view: document.getElementById(id),
				autoStart: true,
				backgroundAlpha: 0,
				transparent: true,
				width:width,
				height:height
			});

			const models = await Promise.all([
				PIXI.live2d.Live2DModel.from(url),
			]);

			models.forEach((model) => {
				app.stage.addChild(model);

				const scaleX = width / model.width;
				const scaleY = height / model.height;

				// fit the window
				model.scale.set(Math.min(scaleX, scaleY));
			});
			models[0].x = width/2 - models[0].width/2;
			models[0].y = height/2 - models[0].height/2;
			
			if (onhit)
				onhit(models[0]);
		})();

		playerList[id] = {x:x,y:y,speedX:0,speedY:0,id:id,radius:50};
	}
	else if (type == "image"){
		var newCanvas = `<div id=${id} style="position:fixed; top: 0; left: 0" onmousedown="clickPlayerHandler(true,${id});" onmouseup="clickPlayerHandler(false,${id});"><div style="pointer-events:none;user-select: none; background-image: url('${url}'); background-; width:${width}; height:${height};background-repeat: no-repeat;background-size: cover;" /></div>`;
		document.getElementById("gameCanvas").insertAdjacentHTML("beforeend", newCanvas);
		
		playerList[id] = {x:x,y:y,speedX:0,speedY:0,id:id,radius:50};
	}
}